import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { serviceConfiguration } from '../config';
import { Obligation } from '../model/obligation.object';

@Injectable({
  providedIn: 'root'
})
export class ObligationService {

  private _loading: boolean = false;
  public get loading(): boolean {
    return this._loading;
  }

  private _filterNames: Array<string> = [
    'order',
    'tf',
    'tt',
    'year',
    'series',
    'status',
    'company',
    'company_key',
    'car',
    'car_key',
    'updated',
    'favourite',
    'mode', // mode=light,
    'itin_addr',
    'itin_country',
    'itin_zip',
    'itin_city',
    'itin_tf',
    'itin_tt',
  ];

  private _sortNames: Array<string> = [
    'number',
    'series',
    'status',
    'company',
    'car',
    'created',
    'itin_arrival'
  ];

  
  constructor(
    private _http: HttpClient
  ) { }


  // GET /external/obligations/<OBLIGATION_KEY>?token=<TOKEN>
  getObligation(token: string | null, obligation_key: number | null): Observable<any> {
    let url: string = serviceConfiguration.obligations.apiGet;
    if (obligation_key && token) {
      url = url.replace('<OBLIGATION_KEY>', obligation_key.toString());
      url = url.replace('<TOKEN>', token);
    }

    return this._http.get(url);
  }

  // GET /external/obligations/?token=<TOKEN>
  getAllObligations(token: string | null, filterObj: any, page: number = 0, size: number = 10): Observable<any> {
    let url: string = serviceConfiguration.obligations.apiGetAll;
    if (token) {
      url = url.replace('<TOKEN>', token);
    }
    url += '&sort=created_time,desc';
    url += '&page=' + page;
    url += '&size=' + size;
    
    // any filters were defined
    if (filterObj) url += this.filterUrl(filterObj);

    return this._http.get(url);
  }

  // method for creating url string for filtering
  filterUrl(filterObj: any) {
    let result = '';
    let filterKeys: Array<string> = Object.keys(filterObj);
    filterKeys.forEach(
      key => {
        // check possible filters for obligations
        if (this._filterNames.includes(key)) {
          result += '&' + key + '=' + filterObj[key];
        }
      }
    );
    return result;
  }

  // POST /external/obligations/?token=<TOKEN>
  createObligation(token: string | null, data: any): Observable<any> {
    let url: string = serviceConfiguration.obligations.apiCreate;
    if (token && data) {
      url = url.replace('<TOKEN>', token);
    }

    return this._http.post(url, data);
  }

  // POST /external/obligations/<OBLIGATION_KEY>/itinerary/?token=<TOKEN>
  createItinerary(obligation_key: number | null, token: string | null, data: any) {
    let url: string = serviceConfiguration.obligations.apiCreateItinerary;
    if (obligation_key && token && data) {
      url = url.replace('<OBLIGATION_KEY>', obligation_key.toString());
      url = url.replace('<TOKEN>', token);
    }

    return this._http.post(url, data);
  }


  /****************************************/
  /* Other required data */
  /****************************************/
  // GET /external/obligations/?token=<TOKEN>&obligation=<OBLIGATION>&size=thumb
  getThumbnailAttachment(path: string, token: string | null, obligation: string | null): Observable<any> {
    let url: string = serviceConfiguration.obligations.apiThumbnail;
    if (path && obligation && token) {
      url = url.replace('<FILE>', path);
      url = url.replace('<OBLIGATION>', obligation);
      url = url.replace('<TOKEN>', token);
    }
    
    return this._http.get(url, {responseType: 'blob'});
  }

  // GET /external/obligations/?token=<TOKEN>&obligation=<OBLIGATION>
  getAttachmentFromObligation(path: string, token: string | null, obligation: string | null): Observable<any> {
    let url: string = serviceConfiguration.obligations.apiAttachmentGet;
    if (path && obligation && token) {
      url = url.replace('<FILE>', path);
      url = url.replace('<OBLIGATION>', obligation);
      url = url.replace('<TOKEN>', token);
    }
    
    return this._http.get(url, {responseType: 'blob'});
  }

  // POST /external/obligations/?token=<TOKEN>&obligation=<OBLIGATION>
  uploadAttachmentToObligation(token: string | null, obligation: string | null, file: any): Observable<any> {
    let url: string = serviceConfiguration.obligations.apiAttachmentPost;
    if (obligation && token) {
      url = url.replace('<OBLIGATION>', obligation);
      url = url.replace('<TOKEN>', token);
    }
    
    // Normalize to separate diacritics
    let sanitized_filename = file.name.normalize("NFKD"); 
    // Remove diacritics
    sanitized_filename = sanitized_filename.replace(/\p{Diacritic}/gu, "");
    // Replace non-alphanumeric (except dots) with "_"
    sanitized_filename = sanitized_filename.replace(/[^a-zA-Z0-9.]+/g, "_"); 

    let formData = new FormData();
    // formData.append('file', file);
    formData.append('file', file, sanitized_filename);

    return this._http.post(url, formData);
  }

  // GET /external/obligations/company/?token=<TOKEN>
  getCompany(token: string | null): Observable<any> {
    let url: string = serviceConfiguration.obligations.apiCompany;
    if (token) {
      url = url.replace('<TOKEN>', token);
    }

    return this._http.get(url);
  }

  // GET /external/obligations/book?token=<TOKEN>
  getBookItem(token: string | null): Observable<any> {
    let url: string = serviceConfiguration.obligations.apiBookItem;
    if (token) {
      url = url.replace('<TOKEN>', token);
    }

    return this._http.get(url);
  }

  // GET /external/obligations/settings/docs/?token=<TOKEN>
  getSettingsDocs(token: string | null): Observable<any> {
    let url: string = serviceConfiguration.obligations.apiSettings;
    if (token) {
      url = url.replace('<TOKEN>', token);
    }

    return this._http.get(url);
  }

  // GET /external/obligations/settings/logo/?token=<TOKEN>
  getLogo(token: string | null): Observable<any> {
    let url: string = serviceConfiguration.obligations.apiLogo;
    if (token) {
      url = url.replace('<TOKEN>', token);
    }

    return this._http.get(url, {responseType: 'blob'});
  }
  
  // PUT /obligations/complete-itinerary?token=<TOKEN>&oblig_key=<OBLIGATION_KEY>&itin_key=<ITINERARY_KEY>
  completeItinerary(token: string | null, obligation_key: number | null, itin_key: number | null, data: any): Observable<any> {
    let url: string = serviceConfiguration.itinerary.apiComplete;
    if (token && obligation_key && itin_key) {
      url = url.replace('<TOKEN>', token);
      url = url.replace('<OBLIGATION_KEY>', obligation_key.toString());
      url = url.replace('<ITINERARY_KEY>', itin_key.toString());
    }
    
    // handling all response (with headers) not just body as usual
    let httpOptions = {
      observe: 'response' as 'body'
    };

    return this._http.put(url, data, httpOptions);
  }

  // GET /external/obligations/<OBLIGATION_KEY>/email?token=<TOKEN>&lang=<LANG>
  sendConfirmationEmail(token: string | null, obligation_key: number | null, lang: string = 'cz'): Observable<any> {
    let url: string = serviceConfiguration.obligations.apiEmail;
    if (token && obligation_key && lang) {
      url = url.replace('<TOKEN>', token);
      url = url.replace('<OBLIGATION_KEY>', obligation_key.toString());
      url = url.replace('<LANG>', lang);
    }
    
    return this._http.get(url);
  }
}
